/*globals $*/
'use strict';

import lozad from 'lozad'
const getSvg = () => import("./external/functions/svg");
const getOwlCarousel = () => import("./external/functions/owl_carousel");
const getHeaderFunctions = () => import("./external/functions/header");
const getLozad = () => import("./external/functions/lozad");

var didScroll;
var lastScrollTop = 0;
var delta = 5;
var navbarHeight = 100; //$('header').outerHeight();

/* DETECT MOBILE */
var isMobile = false;
if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
    || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0,4))) { 
    isMobile = true;
}

$( document ).ready(function() {
    // COOKIES BUTTON
    $('#removecookie').on('click', function() {
       $('#cookie-allow').css('display', 'none');
    });

    //LOAD NAVBAR IF NEEDED
    if($("#nav-open-close").length > 0) {
        getHeaderFunctions().then(headerModule => {
            headerModule.showNavBar();
        });
    }

    //LOAD OWL CAROUSEL IF NEEDED
    if( $('.owl-carousel-banners').length > 0 ) {
        getOwlCarousel().then(owlModule => {
            owlModule.initOwlCarouselBanners();
        });
    }
    if( $('.owl-carousel-products-home').length > 0 ) {
        getOwlCarousel().then(owlModule => {
            owlModule.initOwlCarouselProductsHome();
        });
    }

    if( $('.home-news__container').length > 0 ) {
        getOwlCarousel().then(owlModule => {
            owlModule.initOwlCarouselPostsHome();
        });
    }

    if( $('#product-details__gallery').length > 0 ) {
        getOwlCarousel().then(owlModule => {
            owlModule.initOwlCarouselProductInfo();
        });
    }

    if( $('#product-content__gallery').length > 0 ) {
        getOwlCarousel().then(owlModule => {
            owlModule.initOwlCarouselProductContent();
        });
    }

    //LOAD SVG IF NEEDED
    if($(".svg").length > 0) {
        getSvg().then(svgModule => {
            svgModule.prepareSvg();
        });
    }

    //intervalo de tiempo para calcular constantemente posicion del scroll
    setInterval(function() {
        if (didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

    $('.woocommerce-ordering select').on('change', function() {
        $('.woocommerce-ordering').submit();
    });

    //NAV PRODUCT FEATURES 

    $('[id^="product-feature-nav"]').on('click', function() {
        var id = $(this).data('id');
        if(!$(this).hasClass('opened')){
            closeAllProductFeatures();
            $(this).addClass('opened');
            $('#product-feature-content__' + id).show();
        }
    });

    // $('a.showlogin').on('click', function(){
    //     $('form.woocommerce-form-login').toggle();
    // });

    $('#ship-to-different-address-checkbox').prop('checked', false);

    $('#nif_shipp_field').css('display', 'none');
    $('.woocommerce-shipping-fields__field-wrapper').css('display', 'none');

    $('#ship-to-different-address-checkbox').change(function() {
        if(this.checked) {
            $('#nif_shipp_field').css('display', 'block');
            $('.woocommerce-shipping-fields__field-wrapper').css('display', 'block');
        } else{
            $('#nif_shipp_field').css('display', 'none');
            $('.woocommerce-shipping-fields__field-wrapper').css('display', 'none');
        }
    });

    $('#account_password_field').css('display', 'none');

    $('#createaccount').change(function() {
        if(this.checked) {
            $('#account_password_field').css('display', 'block');
        } else{
            $('#account_password_field').css('display', 'none');

        }
    });

    if($(".single-post-content iframe").length > 0) {
        iframeResize();
    }

});

$(document).ready(function($) {

    if( $('#pa_talla').length > 0 ){
        $('input.variation_id').val( $('#pa_talla option:selected').attr('data-variation') );
            $('#pa_talla').change( function(){
            $('input.variation_id').val( $('#pa_talla option:selected').attr('data-variation') );
        });
    }
    
    if( $('#pa_wing').length > 0 ){
        $('input.variation_id').val( $('#pa_wing option:selected').attr('data-variation') );
            $('#pa_wing').change( function(){
            $('input.variation_id').val( $('#pa_wing option:selected').attr('data-variation') );
        });
    }

    if( $('#pa_color').length > 0 ){
        $('input.variation_id').val( $('#pa_color option:selected').attr('data-variation') );
        $('#pa_color').change( function(){
            $('input.variation_id').val( $('#pa_color option:selected').attr('data-variation') );
        });
    }
    
    addProductPriceHTML( $('option:selected', '.variations select').attr('data-price'), $('option:selected', '.variations select').attr('data-sale'), $('option:selected', '.variations select').attr('data-percent') );
    $('.variations select').change( function(){
        addProductPriceHTML( $('option:selected', this ).attr('data-price'), $('option:selected', this ).attr('data-sale'), $('option:selected', this ).attr('data-percent') );
        /* if( '' != $('input.variation_id').val() ){
            $('p.price').html($('div.woocommerce-variation-price > span.price').html()).append('<p class="availability">'+$('div.woocommerce-variation-availability').html()+'</p>');
            console.log($('input.variation_id').val());
        } else {
            $('p.price').html($('div.hidden-variable-price').html());
            if($('p.availability'))
                $('p.availability').remove();
            console.log('NULL');
        } */
    });
});


function addProductPriceHTML(regular, sale, percent){
    if( percent > 0 ){
        $('#variable__price').empty();
        $('#variable__price').append( '<p class="price"> <del> <span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">€</span>'+parseFloat(regular).toFixed(2)+'</span></del> <ins><span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">€</span>'+parseFloat(sale).toFixed(2)+'</span></ins>  <span class="price__sale">'+percent+'%</span> </p>' );
    }else{
        $('#variable__price').empty();
        $('#variable__price').append( '<p class="price"> <span class="woocommerce-Price-amount amount"><span class="woocommerce-Price-currencySymbol">€</span>'+parseFloat(regular).toFixed(2)+'</span> </p>' );
    }
}

$(window).scroll(function(){ 
     didScroll = true;
});
$(window).resize(function(){ 
    //iframeResize();
});

//Lazy load of all the images
document.addEventListener('DOMContentLoaded', function(){
    if($(".lozad").length > 0) {
        getLozad().then(lozadModule => {
            lozadModule.lozadLoad();
        });
    }
});

function iframeResize() {
  $('iframe').each(function () {
    var ratio = $(this).attr('height') / $(this).attr('width');
    var contentWidth = $(this).parent().width();
    $(this).attr('width', contentWidth).attr('height', contentWidth * ratio);
  });
}

function hasScrolled() {
    var st = $(window).scrollTop();
    // Make sure they scroll more than delta
    /*if(Math.abs(lastScrollTop - st) <= delta)
        return;*/

    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    /*if (st > lastScrollTop && st > navbarHeight){
        // Scroll Down
        //$('header').removeClass('nav-down').addClass('nav-up');
        $('.header').addClass('fixed');
    } else {
        // Scroll Up
        if(st + $(window).height() < $(document).height()) {
            //$('header').removeClass('nav-up').addClass('nav-down');
            $('.header').removeClass('fixed');
        }
    }*/

    if (st > navbarHeight){
        $('.header').addClass('fixed');
    } else {
        $('.header').removeClass('fixed');
    }

    //lastScrollTop = st;
}

function closeAllProductFeatures() {
    $('[id^="product-feature-content"]').hide();
    $('[id^="product-feature-nav"]').removeClass('opened');
    $('[id^="product-feature-nav"]').css('opacity','0.3');
}